#contact {
  background: var(--newsletter-bg);
  padding: 60px 0;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.contact__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.contact__content h2 {
  font-size: 2.5rem;
  color: var(--heading-color);
  line-height: 1.3;
}

.contact__content .highlight {
  color: var(--primary-color);
  font-weight: bold;
}

.contact__form {
  width: 50%;
  padding: 1rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  transition: box-shadow 0.3s ease;
}

.contact__form:hover {
  box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.1);
}

.contact__form input,
.contact__form textarea {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  border: 2px solid #ddd;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.contact__form input:focus,
.contact__form textarea:focus {
  border-color: var(--primary-color);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.contact__form textarea {
  resize: none;
  height: 150px;
}

.contact__form button {
  padding: 0.7rem 1.5rem;
  font-size: 1.1rem;
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact__form button:hover {
  background-color: darken(var(--primary-color), 10%);
  color: var(--primary-color) ;
  transform: scale(1.05);
}

.result {
  display: block;
  margin-top: 20px;
  font-size: 1rem;
  color: var(--primary-color);
}

.light-theme .contact {
  background: linear-gradient(
    180deg,
    rgba(189, 243, 255, 1) 29%,
    rgba(193, 243, 255, 1) 50%,
    rgba(226, 250, 255, 1) 78%
  );
}

@media only screen and (max-width: 992px) {
  .contact__wrapper {
    flex-wrap: wrap;
    gap: 20px;
  }

  .contact__content h2 {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 857px) {
  .contact__wrapper {
    flex-direction: column;
  }

  .contact__form {
    width: 100%;
    gap: 15px;
  }

  .contact__content {
    margin-bottom: 30px;
  }

  .contact__form input,
  .contact__form textarea {
    padding: 10px;
    font-size: 0.9rem;
  }

  .contact__form button {
    font-size: 1rem;
    padding: 0.6rem 1.2rem;
  }
}
