.blog__top-content {
  text-align: center;
  margin-bottom: 100px;
}

.blog__wrapper {
  display: flex;
  justify-content: center; /* Center the card horizontally */
  align-items: center; /* Center the card vertically */
}

.blog__item {
  width: 40%;
  padding: 40px 30px; /* Increased padding for spacious feel */
  background: var(--card-bg);
  border-radius: 1rem; /* Rounded corners */
  cursor: pointer;
  text-align: center; 
  box-shadow: 0 8px 30px rgba(109, 109, 109, 0.25); /* Enhanced shadow for depth */
  transition: transform 0.2s; /* Smooth scaling effect */
  margin: 20px; /* Margin for spacing between cards (if there are multiple) */
}

.blog__item:hover {
  transform: scale(1.05); /* Slightly enlarges on hover */
}

.blog__item h3 {
  color: var(--heading-color);
  font-weight: 600; /* Increased weight for emphasis */
  font-size: 1.7rem; /* Slightly larger size */
  margin-bottom: 10px; /* Reduced margin for better spacing */
}

.short-desc {
  font-size: 1.1rem; /* Size for the short description */
  color: var(--primary-color); /* Use primary color for emphasis */
  margin-bottom: 15px; /* Space between short and long description */
}

.blog__desc {
  font-size: 1rem; /* Size for the description */
  margin-bottom: 15px; /* Added margin for better spacing */
}

.social-links {
  margin-top: 20px;
}

.social-links a {
  margin: 0 10px;
  font-size: 1.8rem; /* Adjust icon size */
  color: #333; /* Change color as needed */
}

.social-links a:hover {
  color: #0073e6; /* Change color on hover */
}

.profile-image {
  width: 100%; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
  border-radius: 50%; /* Circular image */
  margin-bottom: 15px; /* Space below the image */
}

@media only screen and (max-width: 992px) {
  .blog__top-content h2 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .blog__item {
    width: 45%;
    margin-bottom: 30px;
  }
  .blog__wrapper {
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
  }
}

@media only screen and (max-width: 576px) {
  .blog__item {
    width: 100%; /* Full width on small screens */
  }
}
